import { ICart } from '../Context/CartContext'
import { IColorScheme } from '../Context/ThemeContext'
import { IRoom } from '../Pages/Settings/Settings'

export type ISettings = ReturnType<typeof Settings>

type IAnonymousBooking = '' | 'account' | 'sameDayAnonymous'

export const Settings = () => {
    const getFilterStatus = (filter: 'calendar' | 'genre' | 'region') =>
        localStorage.getItem(`filter_${filter}`) === '1'

    return {
        getTerminal: () => localStorage.getItem('terminal'),
        getPrinter: () => localStorage.getItem('printer'),
        getPwUrl: () => localStorage.getItem('pwUrl'),
        getCode: () => {
            const code = localStorage.getItem('code')

            return code !== null ? code : ''
        },
        setCode: (code: string) => localStorage.setItem('code', code),
        setTerminal: (terminal: string) => localStorage.setItem('terminal', terminal),
        setPrinter: (printer: string) => localStorage.setItem('printer', printer),
        setPwUrl: (pwUrl?: string) => pwUrl && localStorage.setItem('pwUrl', pwUrl),
        getCartEnabled: () => localStorage.getItem('cartEnabled') === '1',
        setCartEnabled: (cartEnabled: boolean) =>
            localStorage.setItem('cartEnabled', cartEnabled ? '1' : '0'),
        getSearchEnabled: () => localStorage.getItem('searchEnabled') === '1',
        setSearchEnabled: (searchEnabled: boolean) =>
            localStorage.setItem('searchEnabled', searchEnabled ? '1' : '0'),
        getCart: (): ICart => {
            const cart = localStorage.getItem('cart')

            return cart ? JSON.parse(cart) : {}
        },
        setCart: (cart: ICart) => {
            localStorage.setItem('cart', JSON.stringify(cart))
        },
        getAnonymousBooking: () => localStorage.getItem('anonymousBooking') as IAnonymousBooking,
        setAnonymousBooking: (anonymousBooking: IAnonymousBooking) =>
            localStorage.setItem('anonymousBooking', anonymousBooking),
        getColorScheme: (): IColorScheme => {
            const colorScheme = localStorage.getItem('colorScheme')

            return colorScheme ? JSON.parse(colorScheme) : {}
        },
        setColorScheme: (colorScheme: IColorScheme) => {
            localStorage.setItem('colorScheme', JSON.stringify(colorScheme))
        },
        getUseColorScheme: () => localStorage.getItem('useColorScheme'),
        setUseColorScheme: (value: string) => localStorage.setItem('useColorScheme', value),
        getFilterStatus,
        setFilterStatus: (filter: 'calendar' | 'genre' | 'region', value: boolean) => {
            localStorage.setItem(`filter_${filter}`, value ? '1' : '0')

            if (filter === 'calendar' && value) {
                localStorage.setItem('EndlessScroll', '0')
            }
        },
        areFiltersEnabled: () =>
            getFilterStatus('calendar') || getFilterStatus('genre') || getFilterStatus('region'),
        getShowDateOnCard: () => localStorage.getItem('showDateOnCard') === '1',
        setShowDateOnCard: (value: boolean) =>
            localStorage.setItem('showDateOnCard', value ? '1' : '0'),
        getShowDurationOnCard: () => {
            if (localStorage.getItem('showDurationOnCard') === null) {
                return true
            }

            return localStorage.getItem('showDurationOnCard') === '1'
        },
        setShowDurationOnCard: (value: boolean) =>
            localStorage.setItem('showDurationOnCard', value ? '1' : '0'),
        getShowAvailableSeatsOnCard: () => {
            if (localStorage.getItem('showAvailableSeatsOnCard') === null) {
                return true
            }

            return localStorage.getItem('showAvailableSeatsOnCard') === '1'
        },
        setShowAvailableSeatsOnCard: (value: boolean) => {
            localStorage.setItem('showAvailableSeatsOnCard', value ? '1' : '0')
        },
        getShowLayout: () => {
            if (localStorage.getItem('showLayout') === null) {
                return true
            }

            return localStorage.getItem('showLayout') === '1'
        },
        setShowLayout: (value: boolean) => localStorage.setItem('showLayout', value ? '1' : '0'),
        getEndlessScroll: () => localStorage.getItem('EndlessScroll') === '1',
        setEndlessScroll: (value: boolean) => {
            localStorage.setItem('EndlessScroll', value ? '1' : '0')

            if (value) {
                localStorage.setItem(`filter_calendar`, '0')
            }
        },
        getExcludeSoldOut: () => localStorage.getItem('excludeSoldOut') === '1',
        setExcludeSoldOut: (value: boolean) =>
            localStorage.setItem('excludeSoldOut', value ? '1' : '0'),
        getShowCategorySelection: () => localStorage.getItem('showCategorySelection') === '1',
        setShowCategorySelection: (value: boolean) =>
            localStorage.setItem('showCategorySelection', value ? '1' : '0'),
        getRooms: (): IRoom[] => JSON.parse(localStorage.getItem('selectedRooms') ?? '[]'),
        setRooms: (MultiValue: IRoom[]) => {
            localStorage.setItem('selectedRooms', JSON.stringify(MultiValue))
        },
        getCategoryLocations: (): IRoom[] =>
            JSON.parse(localStorage.getItem('categoryLocations') ?? '[]'),
        setCategoryLocations: (MultiValue: IRoom[]) => {
            localStorage.setItem('categoryLocations', JSON.stringify(MultiValue))
        }
    }
}
