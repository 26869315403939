import moment from 'moment'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useStartPayment } from '.'
import Button from '../Components/Button'
import Overlay from '../Components/EventDetail/Component/Overlay'
import { FinalizeButton } from '../Components/EventDetail/styled'
import { useService } from '../Container/ContainerContext'
import { useAppContext } from '../Context/AppContext'
import { ISettings } from '../Settings/Settings'
import { settingsId } from '../Settings/container'
import { IApiEvent } from '../api/ApiAgent'
import { styled } from '../styles/styled'

const InlineP = styled.p`
    display: inline-block;
    width: 50%;

    ${Button}, ${FinalizeButton} {
        margin: 4px;
    }
`

export const useOrder = ({ events, totalPrice }: { events: IApiEvent[]; totalPrice: number }) => {
    const settings = useService<ISettings>(settingsId)
    const navigate = useNavigate()
    const appContext = useAppContext()

    const [successWindowOpen, setSuccessWindowOpen] = useState(false)
    const [errorWindowOpen, setErrorWindowOpen] = useState(false)
    const [paymentActive, setPaymentActive] = useState(false)
    const [accountActive, setAccountActive] = useState(false)
    const [currentEmail, setCurrentEmail] = useState('')

    const {
        status: paymentStatus,
        data: paymentData,
        mutateAsync: paymentMutation
    } = useStartPayment()

    const startPayment = async () => {
        if (
            events.length &&
            (settings.getAnonymousBooking() === 'account' ||
                (settings.getAnonymousBooking() === 'sameDayAnonymous' &&
                    !!events.find((event) => {
                        return moment(event.from).day() !== moment().day()
                    }))) &&
            !currentEmail.match(new RegExp('.+@.+\\..+'))
        ) {
            return
        }

        setAccountActive(false)
        setPaymentActive(true)

        await paymentMutation({
            events,
            email: currentEmail
        })

        setPaymentActive(false)
        setCurrentEmail('')
    }

    useEffect(() => {
        if (paymentStatus === 'success' && paymentData.success === true) {
            setSuccessWindowOpen(true)
        } else if (paymentStatus === 'success' && paymentData.success === false) {
            setErrorWindowOpen(true)
        }
    }, [paymentStatus, paymentData])

    const resetAccountState = () => {
        setAccountActive(false)
        setCurrentEmail('')
    }

    return {
        setAccountActive,
        startPayment,
        Overlays: (
            <>
                {paymentActive && totalPrice !== 0 && (
                    <Overlay progressBarTime={45} title="Betaling">
                        <p>Je betaling is gestart. Volg de instructies op de pinautomaat.</p>
                        <p>
                            Om je betaling te annuleren, druk je op rode "stop" knop van de
                            pinautomaat.
                        </p>
                    </Overlay>
                )}

                {paymentActive && totalPrice === 0 && (
                    <Overlay progressBarTime={45} title="Reservering">
                        <p>Je reservering wordt verwerkt.</p>
                    </Overlay>
                )}

                {successWindowOpen && (
                    <Overlay
                        role="success"
                        title={totalPrice === 0 ? 'Gelukt' : 'Betaald'}
                        progressBarTime={5}
                        onProgressExpired={() => {
                            setSuccessWindowOpen(false)
                            navigate('/')
                        }}
                    >
                        <p>
                            Je {totalPrice === 0 ? 'reservering' : 'betaling'} is verwerkt en de
                            kaarten zijn geprint.
                        </p>
                    </Overlay>
                )}

                {errorWindowOpen && (
                    <>
                        {paymentData.code >= 4e6 && paymentData.code < 5e6 ? (
                            <Overlay
                                role="error"
                                title="Fout"
                                onProgressExpired={() => {
                                    setErrorWindowOpen(false)
                                    navigate('/')
                                }}
                            >
                                <p>Het printen van de kaarten is niet gelukt.</p>

                                <p>
                                    Meld je even bij een medewerker, die helpt je verder.
                                    Reserveringnummer: {paymentData.reservationId}
                                </p>
                            </Overlay>
                        ) : (
                            <Overlay
                                role="error"
                                title="Fout"
                                progressBarTime={5}
                                onProgressExpired={() => {
                                    setErrorWindowOpen(false)
                                }}
                            >
                                <p>Je reservering is niet afgerond met de volgende reden:</p>
                                <p>{paymentData.error}</p>
                            </Overlay>
                        )}
                    </>
                )}

                {accountActive && (
                    <Overlay
                        progressBarTime={45}
                        title={totalPrice === 0 ? 'Reservering' : 'Betaling'}
                        onProgressExpired={() => resetAccountState()}
                    >
                        <p>Vul uw emailadres in.</p>
                        <p>
                            <input
                                value={currentEmail}
                                onChange={(e) => setCurrentEmail(e.target.value)}
                                pattern={'.+@.+\\..+'}
                            />
                        </p>
                        <InlineP>
                            <Button onClick={() => resetAccountState()}>Annuleer</Button>
                        </InlineP>
                        <InlineP>
                            <Button
                                disabled={
                                    !events.length || !currentEmail.match(new RegExp('.+@.+\\..+'))
                                }
                                onClick={startPayment}
                            >
                                {appContext.appSettings?.customText?.orderButton || 'Afrekenen'}
                            </Button>
                        </InlineP>
                    </Overlay>
                )}
            </>
        )
    }
}
