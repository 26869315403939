import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { SetupApiAgent, SetupApiCall } from '../api'
import { setupApiAgentId } from '../api/container'
import { useService } from '../Container/ContainerContext'
import { useAppContext } from '../Context/AppContext'
import { settingsId } from '../Settings/container'
import { ISettings } from '../Settings/Settings'

export const useGetAppSettings = () => {
    const setupApiAgent = useService<SetupApiAgent>(setupApiAgentId)
    const appContext = useAppContext()
    const settings = useService<ISettings>(settingsId)
    const navigate = useNavigate()
    const [code, setCode] = useState(settings.getCode() || '')

    const [loaded, setLoaded] = useState(false)
    const [redirect, setRedirect] = useState(false)

    const { refetch } = useQuery(
        SetupApiCall.INFO,
        () => {
            return setupApiAgent.getInfo(code)
        },
        {
            enabled: false,
            refetchOnWindowFocus: false,
            retry: false,
            onSuccess: (data) => {
                if (data.success) {
                    settings.setCode(code)
                    appContext.setAppSettings(data)

                    if (redirect) {
                        navigate('/')
                    }
                } else {
                    if (data.errorMessage && code !== '') {
                        setCode('')
                        alert(data.errorMessage)
                    }
                }

                setLoaded(true)
            },
            onError: () => {
                setLoaded(true)
            }
        }
    )

    useEffect(() => {
        if (code) {
            refetch()
        } else {
            setLoaded(true)
        }
    }, [code]) // eslint-disable-line

    return { setRedirect, loaded, code, setCode }
}
