import Bottle from 'bottlejs'
import { createContext, useContext } from 'react'

const ContainerContext = createContext<any>({})

export const ContainerContextProvider = ContainerContext.Provider
export const ContainerContextConsumer = ContainerContext.Consumer

export const useContainer = function (): Bottle {
    return useContext(ContainerContext as any)
}

export const useService = <T>(name: string): T => {
    return useContext(ContainerContext).container[name]
}
