import { useQuery } from 'react-query'

import { ApiAgent, ApiCall } from '../api'
import { apiAgentId } from '../api/container'
import { useService } from '../Container/ContainerContext'
import { ApiResponse, IPriceCode } from '../Model'

export const usePriceCodes = (eventId: number, rankId: number | null) => {
    const apiAgent = useService<ApiAgent>(apiAgentId)

    const { data, status } = useQuery(
        [ApiCall.PRICECODES, { eventId, rankId, amount: 1 }],
        () => {
            if (rankId === null) {
                return new Promise<ApiResponse<IPriceCode[][]>>(() => ({ success: false }))
            }

            return apiAgent.getPricecode({ eventId, rankId, amount: 1 })
        },
        {
            enabled: eventId !== null && rankId !== null
        }
    )

    return { data, status }
}
