import { useRef, useState } from 'react'
import { Color, ColorPicker, toColor } from 'react-color-palette'
import { useOnOutsideClick } from '../../Hooks/useOnOutsideClick'
import Overlay from '../EventDetail/Component/Overlay'
import { ColorPreview, Wrapper } from './styled'

export const Colorpicker = ({
    hexColor,
    onChange
}: {
    hexColor: string
    onChange: (hex: string) => void
}) => {
    const color = toColor('hex', hexColor)

    const [active, setActive] = useState(false)

    const ref = useRef<any>()

    useOnOutsideClick({ active, nodes: [ref], onOutsideClick: () => setActive(false) })

    return (
        <>
            <td onClick={() => setActive(true)}>
                <ColorPreview color={color.hex} />

                {active && (
                    <Overlay role="success" title="Betaald">
                        <div ref={ref}>
                            <Wrapper>
                                <ColorPicker
                                    width={280}
                                    color={color}
                                    onChange={(e) => onChange((e as Color).hex)}
                                    hideRGB={true}
                                    hideHSV={true}
                                    dark={true}
                                />
                            </Wrapper>
                        </div>
                    </Overlay>
                )}
            </td>

            <td onClick={() => setActive(true)}>{hexColor}</td>
        </>
    )
}
