import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { ApiAgent, ApiCall } from '../api'
import { apiAgentId } from '../api/container'
import { useService } from '../Container/ContainerContext'

export const useEvent = (eventId: number) => {
    const apiAgent = useService<ApiAgent>(apiAgentId)

    const { data, status } = useQuery(
        [ApiCall.EVENTS],
        () => apiAgent.getEvents({ filter: { type: 'id', eventId } }),
        {
            enabled: eventId !== null
        }
    )

    const event = useMemo(() => {
        if (status === 'success' && data?.success === true) {
            return data.data[0] ?? null
        }
        return null
    }, [data, status])

    return { status, event }
}
