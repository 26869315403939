import { useMutation } from 'react-query'

import { ApiAgent } from '../api'
import { apiAgentId } from '../api/container'
import { useService } from '../Container/ContainerContext'

export const useStartPayment = () => {
    const apiAgent = useService<ApiAgent>(apiAgentId)

    const { mutateAsync, data, status } = useMutation(apiAgent.startPayment)

    return { mutateAsync, data, status }
}
