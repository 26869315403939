import { useQuery } from 'react-query'
import { ApiAgent, ApiCall } from '../api'
import { apiAgentId } from '../api/container'
import { useService } from '../Container/ContainerContext'

export const useLocations = () => {
    const apiAgent = useService<ApiAgent>(apiAgentId)

    const { data, status } = useQuery([ApiCall.LOCATIONS], () => apiAgent.getLocations())

    return { data, status }
}
