import Button from '../../Components/Button'
import { styled } from '../../styles/styled'

export const NoContent = styled.div`
    margin: 0 auto;
    width: 80%;

    padding: 32px;
    margin-top: 20px;

    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    text-align: center;
`

export const Content = styled.div`
    margin: 0 auto;
    width: 80%;

    padding: 16px;
    margin-top: 20px;
    /* overflow-y: scroll; */
    /* height: calc(100vh - 91px); */

    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
`

export const Wrapper = styled.div`
    background-color: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
`

export const ImageContainer = styled.div`
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
`

export const Image = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;

    img {
        width: 100%;
        object-fit: cover;
    }
`

export const EventInfo = styled.div`
    padding: 10px;
    width: 70%;
    display: inline-block;
`

export const EventButtons = styled.div`
    display: inline-block;
    vertical-align: top;
    padding: 10px;

    ${Button} {
        margin: 8px;
    }
`

export const EventTitle = styled.div`
    font-size: 2em;
    font-weight: 600;
`
export const EventSubTitle = styled.div`
    font-size: 1.5em;
    font-weight: 400;
`
export const EventLocation = styled.div``

export const EventDuration = styled.div``

export const EventSelected = styled.div`
    margin-top: 1rem;
    width: fit-content;

    div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.4rem;
        width: 100%;

        span:nth-child(1) {
            margin-right: 2rem;
        }
    }
`

export const ImageTag = styled.span`
    position: relative;
    top: 3rem;
    left: -0.25rem;
    padding: 0.25rem;
    z-index: 100;
    font-size: 0.75rem;
    color: white;
    background-color: ${({ theme }) => theme.imageTagColor};
`

export const FinalizeInfo = styled.div`
    width: 300px;
    margin-left: auto;
    margin-right: 0;
    padding: 30px;
`

export const TotalPrice = styled.div`
    font-weight: 600;
    font-size: 2em;
    text-align: right;
`

export const FinalizeButton = styled(Button)<{ disabled: boolean }>`
    width: 200px;
    background-color: grey;
    border-color: grey;
    ${({ disabled, theme }) =>
        !disabled
            ? `
            background-color: ${theme.finalizeButtonColor};
            border-color: ${theme.finalizeButtonBorderColor};
            `
            : ``}

    margin-left: auto;
    margin-right: 0;
`
