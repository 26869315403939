import { useTheme } from '../../Context/ThemeContext'

export const ProgressBar = ({ completed }: { completed: number }) => {
    const {
        theme: { progressbarColor }
    } = useTheme()

    const containerStyles = {
        height: 5,
        width: '100%',
        backgroundColor: '#e0e0de',
        borderRadius: 0
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        backgroundColor: progressbarColor,
        borderRadius: 'inherit'
    }

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}></div>
        </div>
    )
}
