import { useNavigate } from 'react-router-dom'
import { useService } from '../../Container/ContainerContext'
import { useCart } from '../../Context/CartContext'
import { ISettings } from '../../Settings/Settings'
import { settingsId } from '../../Settings/container'
import {
    Actions,
    BackButton,
    CartButton,
    Header as InnerHeader,
    Logo,
    SearchInput,
    Spacer,
    Wrapper
} from './styled'

export const Header = ({
    onBackButton,
    searchFilter
}: {
    onBackButton?: () => void
    searchFilter?: boolean
}) => {
    const settings = useService<ISettings>(settingsId)
    const navigate = useNavigate()
    const { cart, category, search, setSearch } = useCart()

    let totalAmount = 0

    cart.events.forEach((event) => {
        event.priceCodes.forEach((pc) => {
            if (event.selectedPriceCodes[pc.id]) {
                totalAmount += event.selectedPriceCodes[pc.id]
            }
        })
    })

    return (
        <Wrapper>
            <InnerHeader>
                <Logo />

                {onBackButton && (
                    <Actions>
                        <BackButton onClick={onBackButton}>Terug</BackButton>
                    </Actions>
                )}

                {!(!category && settings.getShowCategorySelection()) &&
                    searchFilter &&
                    settings.getSearchEnabled() && (
                        <SearchInput
                            placeholder="Zoeken"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    )}

                {settings.getCartEnabled() && (
                    <Actions>
                        <CartButton onClick={() => navigate('/winkelmandje')}>
                            Winkelmandje ({totalAmount})
                        </CartButton>
                    </Actions>
                )}
            </InnerHeader>
            <Spacer />
        </Wrapper>
    )
}
