import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Content from '../../Components/Content'
import Header from '../../Components/Header'
import { useGetAppSettings } from '../../Hooks/useGetAppSettings'

export const Setup = () => {
    const [code, setCode] = useState('')
    const getAppSettings = useGetAppSettings()
    const navigate = useNavigate()

    const handleClick = () => {
        if (code !== '' && code === getAppSettings.code) {
            navigate('/')
        }

        getAppSettings.setCode(code)
        getAppSettings.setRedirect(true)
    }

    return (
        <>
            <Header />
            <Content>
                Code: <input type="text" value={code} onChange={(e) => setCode(e.target.value)} />{' '}
                <button onClick={handleClick}>Instellen</button>
            </Content>
        </>
    )
}
