import { styled } from '../../../../styles/styled'

export const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    /* background-color: #000000dd; */
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Popup = styled.div`
    width: 300px;
    height: auto;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: #f3f3f3;
    border-radius: 3px;
`

export const PopupTitle = styled.div<{ role: 'info' | 'success' | 'error' }>`
    font-size: 1.25rem;
    font-weight: 600;
    border-bottom: grey;
    background-color: ${({ role, theme }) =>
        role === 'info'
            ? theme.popupInfoColor
            : role === 'success'
            ? theme.popupInfoColor
            : theme.popupErrorColor};
    color: white;
    padding: 10px;

    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`
export const PopupContent = styled.div`
    padding: 10px;
`

export const AddToCartWrapper = styled.div`
    margin-top: 8px;
`
