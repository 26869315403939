import Bottle from 'bottlejs'
import { once } from 'lodash'
import { createRoot } from 'react-dom/client'

import { initContainer } from './Container/api'
import { getContainerModules, initialize, renderApp } from './index.common'

const render = ({ container }: { container: Bottle }) => {
    const element = window.document.getElementById('root')
    const root = createRoot(element)
    root.render(renderApp({ container }))
}

const container = new Bottle()

declare const window: any
window.container = container.container

const setup = once(() => {
    initContainer(container, getContainerModules())
    initialize({ container })
    render({ container })
})

setup()

declare var module: any
if (module) {
    if (module.hot) {
        module.hot.accept()
    }
}
