import { values } from 'lodash'
import moment from 'moment'
import { IEvent } from '../Model'

export const getGroupedEvents = (events: IEvent[]) => {
    const g: { [x: string]: IEvent[] } = {}

    events.forEach((event) => {
        g[event.title + ' ' + moment(event.from).format('YYYY-MM-DD')] = [
            ...(g[event.title + ' ' + moment(event.from).format('YYYY-MM-DD')] ?? []),
            event
        ]
    })

    return { groupedEvents: values(g) }
}
