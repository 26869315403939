import { styled } from '../../styles/styled'

type ButtonProps = {
    disabled?: boolean
    active?: boolean
}

export const Button = styled.div<ButtonProps>`
    text-align: center;
    background-color: ${({ disabled = false, active = true, theme }) =>
        disabled
            ? theme.disabledButtonColor
            : active === false
            ? theme.inactiveButtonColor
            : active
            ? theme.buttonColor
            : ''};
    border: 1px solid
        ${({ disabled = false, active = true, theme }) =>
            disabled
                ? theme.disabledButtonBorderColor
                : active === false
                ? theme.inactiveButtonBorderColor
                : active
                ? theme.buttonBorderColor
                : ''};

    color: ${({ active }) => (active === false ? 'black' : 'white')};
    padding: 10px 25px;
    border-radius: 3px;
`
