export enum ApiCall {
    EVENTS = 'ITIX_EVENTS',
    EVENTSDATES = 'ITIX_EVENTSDATES',
    RANKS = 'ITIX_RANKS',
    PRICECODES = 'ITIX_PRICECODES',
    IMAGES = 'PEPPERED_IMAGES',
    TERMINALS = 'ITIX_TERMINALS',
    PRINTERS = 'ITIX_PRINTERS',
    REGIONS = 'PW_REGIONS',
    LOCATIONS = 'ITIX_LOCATIONS',
    ROOMS = 'ITIX_ROOMS'
}
