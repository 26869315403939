import { Route, Routes } from 'react-router-dom'
import PrivateRoute from './Auth/PrivateRoute'
import { Bestel } from './Pages/Bestel/Bestel'
import { Cart } from './Pages/Cart/Cart'
import { Home } from './Pages/Home/Home'
import { Settings } from './Pages/Settings/Settings'
import { Setup } from './Pages/Setup/Setup'

export const App = () => (
    <Routes>
        <Route
            path="/"
            element={
                <PrivateRoute>
                    <Home />
                </PrivateRoute>
            }
        />

        <Route
            path="/bestel/:eventId"
            element={
                <PrivateRoute>
                    <Bestel />
                </PrivateRoute>
            }
        />

        <Route
            path="/winkelmandje"
            element={
                <PrivateRoute>
                    <Cart />
                </PrivateRoute>
            }
        />

        <Route
            path="/settings"
            element={
                <PrivateRoute>
                    <Settings />
                </PrivateRoute>
            }
        />

        <Route path="/setup" element={<Setup />} />
    </Routes>
)
