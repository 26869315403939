import moment from 'moment'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useService } from '../Container/ContainerContext'
import { ApiAgent, ApiCall } from '../api'
import { IEventsFilter } from '../api/ApiAgent'
import { apiAgentId } from '../api/container'

export const useEventsDates = ({ filter }: { filter: Partial<IEventsFilter> }) => {
    const apiAgent = useService<ApiAgent>(apiAgentId)

    const memoKey =
        filter.type === 'date'
            ? {
                  ...filter,
                  dateFrom: moment(filter.dateFrom).format('YYYY-MM-DD'),
                  dateUntil: moment(filter.dateUntil).format('YYYY-MM-DD')
              }
            : filter

    const { data: eventResponse, status } = useQuery(
        [ApiCall.EVENTSDATES, JSON.stringify(memoKey)],
        (_) => apiAgent.getEventsDates({ filter }),
        {
            refetchInterval: 30000
        }
    )

    const dates = useMemo(() => {
        return status === 'success' && eventResponse?.success === true ? eventResponse.data : []
    }, [eventResponse, status])

    return { status, dates }
}
