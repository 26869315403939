import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { ApiAgent, ApiCall } from '../api'
import { apiAgentId } from '../api/container'
import { useService } from '../Container/ContainerContext'

export const useRegions = () => {
    const apiAgent = useService<ApiAgent>(apiAgentId)

    const { data: regionResponse, status } = useQuery(
        [ApiCall.REGIONS],
        (_) => apiAgent.getRegions(),
        {
            refetchInterval: 30000
        }
    )

    const regions = useMemo(() => {
        return status === 'success' && regionResponse?.success === true ? regionResponse.data : []
    }, [regionResponse, status])

    return { status, regions }
}
