import Bottle from 'bottlejs'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { apiAgentModule, setupApiAgentModule } from './api/container'
import { App } from './App'
import { configModule } from './Config/container'
import { ContainerContextProvider } from './Container/ContainerContext'
import { AppContext } from './Context/AppContext'
import { CartContext } from './Context/CartContext'
import { ThemeContext } from './Context/ThemeContext'
import { useGetAppSettings } from './Hooks/useGetAppSettings'
import { settingsModule } from './Settings/container'
import { PreflightGlobalStyles } from './styles/Preflight'

export const getContainerModules = () => [
    configModule,
    settingsModule,
    apiAgentModule,
    setupApiAgentModule
]

export const initialize = async ({ container }: { container: Bottle }) => {}

const queryClient = new QueryClient()

export const renderApp = ({ container }: { container: Bottle }) => {
    return (
        <ContainerContextProvider value={container}>
            <QueryClientProvider client={queryClient}>
                <PreflightGlobalStyles />
                <BrowserRouter>
                    <AppContext>
                        <ThemeContext>
                            <CartContext>
                                <AppWrapper />
                            </CartContext>
                        </ThemeContext>
                    </AppContext>
                </BrowserRouter>
            </QueryClientProvider>
        </ContainerContextProvider>
    )
}

const AppWrapper = () => {
    const { loaded } = useGetAppSettings()

    if (!loaded) {
        return null
    }

    return <App />
}
