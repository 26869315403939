import { styled } from '../../styles/styled'
import Button from '../Button'

export const Wrapper = styled.div``

export const AddButton = styled(Button)`
    width: 200px;
`

export const RemoveButton = styled(Button)`
    width: 120px;
`

export const ShowTheme = styled.div`
    margin-top: 16px;

    input.showTheme {
        width: calc(100% - 228px);
        height: 40px;
        display: inline-block;
    }

    ${RemoveButton} {
        margin-left: 16px;
        display: inline-block;
    }
`

export const ShowThemes = styled.div`
    ${AddButton} {
        margin-top: 16px;
    }
`

export const CodeCheck = styled.div`
    margin-left: 16px;
    width: 60px;
    height: 40px;
    display: inline-flex;
    color: white;
    vertical-align: top;
    text-align: center;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
`
