import { createContext, ReactNode, useContext, useState } from 'react'
import { IColorScheme } from './ThemeContext'

export type IAppContext = ReturnType<typeof useInitialState>

export type ICustomText = {
    orderButton?: string
    cinema?: string
    frontSelectTitle?: string
    infoMovieDetail?: string
    infoTheaterDetail?: string
    soldOut?: string
}

export type IAppSettings = {
    apiPwMappingUrl?: string
    baseUrl?: string
    colorScheme?: IColorScheme
    floorplanBaseUrl?: string
    logo?: string
    imageUrl?: string
    pwUrl?: string
    backgroundImageUrl?: string
    customText?: ICustomText
}

export let settingsBaseUrl: string | undefined
export let settingsApiPwMappingUrl: string | undefined

const useInitialState = () => {
    const [appSettings, _setAppSettings] = useState<IAppSettings>()

    return {
        appSettings,
        setAppSettings: (settings: IAppSettings) => {
            settingsBaseUrl = settings?.baseUrl
            settingsApiPwMappingUrl = settings?.apiPwMappingUrl

            _setAppSettings(settings)
        }
    }
}

const Context = createContext<IAppContext>({} as IAppContext)

export const AppContext = ({ children }: { children: ReactNode }) => {
    const initialState = useInitialState()

    return <Context.Provider value={initialState}>{children}</Context.Provider>
}

export const useAppContext = () => useContext(Context)
