import { bindToFactory } from '../Container/api'
import { ApiAgent } from './ApiAgent'
import { SetupApiAgent } from './SetupApiAgent'

export const apiAgentId = 'apiAgent'
export const apiAgentModule = bindToFactory(apiAgentId, ({ config, settings }) => {
    return new ApiAgent({
        config,
        settings
    })
})

export const setupApiAgentId = 'setupApiAgent'
export const setupApiAgentModule = bindToFactory(setupApiAgentId, () => {
    return new SetupApiAgent()
})
