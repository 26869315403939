import { css } from 'styled-components'
import { styled } from '../../styles/styled'
import Button from '../Button'
import { BackButton } from '../Header/styled'

export const Wrapper = styled.div`
    padding: 20px;
`

export const FilterWrapper = styled.div``

export const Filters = styled.div`
    display: flex;
    justify-content: left;
`

export const Filter = styled.div`
    width: 50%;
    padding: 16px;

    ul {
        padding: 0;
    }
`

export const FilterTitle = styled.div`
    display: block;
    font-weight: 700;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.25rem;
    line-height: 1;
`

export const ContentWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;

    ${BackButton} {
        position: absolute;
        bottom: 1.4rem;
        right: 1.4rem;
        z-index: 10;
    }
`

export const CalendarWrapper = styled.div`
    .react-calendar {
        width: 100%;
        background: white;
        border: 1px solid #a0a096;
        font-family: Arial, Helvetica, sans-serif;
        line-height: 1.125em;
    }
    .react-calendar--doubleView {
        width: 700px;
    }
    .react-calendar--doubleView .react-calendar__viewContainer {
        display: flex;
        margin: -0.5em;
    }
    .react-calendar--doubleView .react-calendar__viewContainer > * {
        width: 50%;
        margin: 0.5em;
    }
    .react-calendar,
    .react-calendar *,
    .react-calendar *:before,
    .react-calendar *:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    .react-calendar button {
        margin: 0;
        border: 0;
        outline: none;
    }
    .react-calendar button:enabled:hover {
        cursor: pointer;
    }
    .react-calendar__navigation {
        display: flex;
        height: 44px;
        margin-bottom: 1em;
    }
    .react-calendar__navigation button {
        min-width: 44px;
        background: none;
    }
    .react-calendar__navigation button:disabled {
        background-color: #f0f0f0;
    }
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
        background-color: #e6e6e6;
    }
    .react-calendar__month-view__weekdays {
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.75em;
    }
    .react-calendar__month-view__weekdays__weekday {
        padding: 0.5em;
    }
    .react-calendar__month-view__weekNumbers .react-calendar__tile {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.75em;
        font-weight: bold;
    }
    .react-calendar__month-view__days__day--weekend {
        color: #d10000;
    }
    .react-calendar__month-view__days__day--neighboringMonth {
        color: #757575;
    }
    .react-calendar__year-view .react-calendar__tile,
    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__century-view .react-calendar__tile {
        padding: 2em 0.5em;
    }
    .react-calendar__tile {
        max-width: 100%;
        padding: 10px 6.6667px;
        background: none;
        text-align: center;
        line-height: 16px;
    }
    .react-calendar__tile:disabled {
        background-color: #f0f0f0;
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
        background-color: #e6e6e6;
    }
    .react-calendar__tile--now {
        background: #ffff76;
    }
    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
        background: #ffffa9;
    }
    .react-calendar__tile--hasActive {
        background: #76baff;
    }
    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
        background: #a9d4ff;
    }
    .react-calendar__tile--active {
        background: #006edc;
        color: white;
    }
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background: #1087ff;
    }
    .react-calendar--selectRange .react-calendar__tile--hover {
        background-color: #e6e6e6;
    }

    abbr[title] {
        text-decoration: none;
    }
`

export const FilterItem = styled.li<{ selected: boolean }>`
    position: relative;
    transition: all 250ms;
    list-style: none;
    margin-top: 4px;

    p {
        display: inline-block;
        text-decoration: underline;
        color: #0061a7;
        cursor: pointer;
        padding: 0;
        margin: 0;
    }

    ${(p) =>
        p.selected &&
        css`
            p {
                color: #000;
            }
        `}
`

export const ToggleFilterButton = styled(Button)`
    display: inline-block;
    width: 200px;
    margin-bottom: 16px;
`

export const ClearFiltersButton = styled(Button)`
    display: inline-block;
    margin-left: 16px;
`

export const CurrentFilters = styled.div`
    margin-bottom: 16px;
`
export const RefDiv = styled.div`
    display: flex;
    align-self: flex-end;
    width: 100%;
    height: 10px;
`

export const FrontSelection = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 75vh;

    .buttonwrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        grid-gap: 1rem;
    }
`

export const FrontTitle = styled.div`
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 5rem;
    font-weight: bold;
`

export const FrontSelectionBlock = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
    background-color: ${({ theme }) => theme.buttonColor};
    border-color: ${({ theme }) => theme.buttonBorderColor};
    color: white;
    font-size: 2rem;
    font-weight: bold;
`
export const FrontSelectionBackground = styled.div<{ url?: string }>`
    background-image: url(${(props) => (props.url ? props.url : '')});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -10;
    position: absolute;
    height: calc(100vh - 16px);
    width: 100vw;
    overflow: hidden;
`
