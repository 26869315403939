import { createGlobalStyle } from 'styled-components'

/* from tailwindcss/dist/base.css */
export const PreflightGlobalStyles = createGlobalStyle`
html,
body {
    padding: 0;
    margin: 0;
    font-family: "Arial MT W01", sans-serif;
    font-size: 16px;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

body {

}

body, div, table, td, li, input, select, textarea, p {
  font-size: 1rem;
  font-family: "Arial MT W01", sans-serif;
}

::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

@font-face {
    font-family: 'Arial MT W01';
    src: url('./Fonts/413af1a3-8b25-474c-9bb6-8d89bb907596.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Arial MT W01';
    src: url('.//Fonts/a711e122-8217-4abf-9182-77dc703c5bf8.woff');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Arial MT W01';
    src: url('.//Fonts/2d02673c-1266-421e-8a31-241190c60831.woff');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Arial MT W01';
    src: url('.//Fonts/43ef312d-ae60-45e2-8a2f-860e6c0f7d16.woff');
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Arial MT W01';
    src: url('.//Fonts/d3acd276-9323-4d81-a9a1-5770c58108c5.woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Arial MT W01';
    src: url('https://www.chasse.nl/Fonts/edcddada-9b91-4110-9aff-bcdfd01c3e10.woff');
    font-weight: 700;
    font-style: italic;
}

`
