import Content from '../../Components/Content'
import Header from '../../Components/Header'
import { Overview } from '../../Components/Overview/Overview'
import {
    FrontSelection,
    FrontSelectionBlock,
    FrontTitle
} from '../../Components/Overview/StyledOverview'
import { useService } from '../../Container/ContainerContext'
import { useAppContext } from '../../Context/AppContext'
import { useCart } from '../../Context/CartContext'
import { ISettings } from '../../Settings/Settings'
import { settingsId } from '../../Settings/container'

export const Home = () => {
    const settings = useService<ISettings>(settingsId)
    const appContext = useAppContext()

    const { category, setCategory } = useCart()

    return (
        <>
            <Header searchFilter={true} />
            <Content>
                {!category && settings.getShowCategorySelection() ? (
                    <FrontSelection>
                        {/* <FrontSelectionBackground url={appContext.appSettings?.backgroundImageUrl} />  */}
                        <FrontTitle>
                            {appContext.appSettings?.customText?.frontSelectTitle || ''}
                        </FrontTitle>

                        <div className="buttonwrapper">
                            <FrontSelectionBlock
                                onClick={() => {
                                    setCategory({ type: 'category', category: 'theater' })
                                }}
                            >
                                Theater
                            </FrontSelectionBlock>

                            <FrontSelectionBlock
                                onClick={() => {
                                    setCategory({ type: 'category', category: 'film' })
                                }}
                            >
                                {appContext.appSettings?.customText?.cinema || 'Cinema'}
                            </FrontSelectionBlock>

                            {settings.getCategoryLocations().map((cl) => (
                                <FrontSelectionBlock
                                    key={cl.value}
                                    onClick={() => {
                                        setCategory({ type: 'location', location: cl.value })
                                    }}
                                >
                                    {cl.label}
                                </FrontSelectionBlock>
                            ))}
                        </div>
                    </FrontSelection>
                ) : (
                    <Overview />
                )}
            </Content>
        </>
    )
}
