import { useEffect, useState } from 'react'

export const useIsInViewport = (ref) => {
    const [isIntersecting, setIsIntersecting] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting)
        })

        ref.current && observer.observe(ref.current)

        return () => {
            observer.disconnect()
        }
    })

    return isIntersecting
}
