import { useRef } from 'react'
import { useQuery } from 'react-query'
import MultiSelect from 'react-select'
import Colorscheme from '../../Components/Colorscheme'
import Content from '../../Components/Content'
import Header from '../../Components/Header'
import { useService } from '../../Container/ContainerContext'
import { useAppContext } from '../../Context/AppContext'
import { ISettings } from '../../Settings/Settings'
import { settingsId } from '../../Settings/container'
import { ApiAgent, ApiCall } from '../../api'
import { apiAgentId } from '../../api/container'
import { styled } from '../../styles/styled'

const Select = styled.select`
    font-size: 1.5rem;
    width: 500px;
    height: 50px;
`

const SelectContainer = styled.div`
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 50px;
`

export type IRoom = { value: string; label: string }

export const Settings = () => {
    const apiAgent = useService<ApiAgent>(apiAgentId)
    const settings = useService<ISettings>(settingsId)
    const appContext = useAppContext()

    const calendarRef = useRef<HTMLInputElement>(null)
    const endlessScrollRef = useRef<HTMLInputElement>(null)

    const { data: printerData } = useQuery([ApiCall.PRINTERS], apiAgent.getPrinters)
    const { data: terminalData } = useQuery([ApiCall.TERMINALS], apiAgent.getTerminals)
    const { data: roomsData } = useQuery([ApiCall.ROOMS], apiAgent.getRooms)

    const printers = printerData?.printers ?? []
    const terminals = terminalData?.terminals ?? []
    const rooms =
        roomsData?.rooms.map((room) => {
            return { value: room.id, label: room.name }
        }) ?? []

    return (
        <>
            <Header />
            <Content>
                {printers.length > 0 ? (
                    <SelectContainer>
                        Printer:
                        <br />
                        <Select
                            onChange={(e) => settings.setPrinter(e.target.value)}
                            defaultValue={settings.getPrinter() ?? ''}
                        >
                            <option value="">Selecteer een printer</option>
                            {printers.map((p) => (
                                <option key={p.id} value={p.id}>
                                    {p.name}
                                </option>
                            ))}
                        </Select>
                    </SelectContainer>
                ) : (
                    <p>Geen printers gevonden</p>
                )}

                {terminals.length > 0 ? (
                    <SelectContainer>
                        Terminal:
                        <br />
                        <Select
                            onChange={(e) => settings.setTerminal(e.target.value)}
                            defaultValue={settings.getTerminal() ?? ''}
                        >
                            <option value="">Selecteer een terminal</option>
                            {terminals.map((t) => (
                                <option key={t.id} value={t.id}>
                                    {t.id} - {t.name}
                                </option>
                            ))}
                        </Select>
                    </SelectContainer>
                ) : (
                    <p>Geen terminals gevonden</p>
                )}

                <SelectContainer>
                    Zalen:
                    <br />
                    <MultiSelect
                        onChange={(e) => settings.setRooms(Array.from(e))}
                        defaultValue={settings.getRooms()}
                        options={rooms}
                        isMulti
                    />
                </SelectContainer>

                <SelectContainer>
                    Kleurschema keuze:
                    <br />
                    <Select
                        onChange={(e) => settings.setUseColorScheme(e.target.value)}
                        defaultValue={settings.getUseColorScheme() || '0'}
                    >
                        <option value="default">default</option>
                        <option value="custom">custom</option>
                    </Select>
                </SelectContainer>

                <SelectContainer>
                    Kleurschema:
                    <br />
                    <Colorscheme />
                </SelectContainer>

                <SelectContainer>
                    Filters:
                    <br />
                    <table>
                        <tbody>
                            <tr>
                                <td>Kalender</td>
                                <td>
                                    <input
                                        type={'checkbox'}
                                        ref={calendarRef}
                                        defaultChecked={settings.getFilterStatus('calendar')}
                                        onChange={(e) => {
                                            settings.setFilterStatus('calendar', e.target.checked)

                                            if (endlessScrollRef.current) {
                                                endlessScrollRef.current.checked = false
                                            }
                                        }}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Endless scroll</td>
                                <td>
                                    <input
                                        type={'checkbox'}
                                        ref={endlessScrollRef}
                                        defaultChecked={settings.getEndlessScroll() || false}
                                        onChange={(e) => {
                                            settings.setEndlessScroll(e.target.checked)

                                            if (calendarRef.current) {
                                                calendarRef.current.checked = false
                                            }
                                        }}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Genre</td>
                                <td>
                                    <input
                                        type={'checkbox'}
                                        defaultChecked={settings.getFilterStatus('genre')}
                                        onChange={(e) =>
                                            settings.setFilterStatus('genre', e.target.checked)
                                        }
                                    />
                                </td>
                            </tr>

                            {appContext.appSettings?.apiPwMappingUrl && (
                                <tr>
                                    <td>Regio</td>
                                    <td>
                                        <input
                                            type={'checkbox'}
                                            defaultChecked={settings.getFilterStatus('region')}
                                            onChange={(e) =>
                                                settings.setFilterStatus('region', e.target.checked)
                                            }
                                        />
                                    </td>
                                </tr>
                            )}

                            <tr>
                                <td>Uitverkocht uitsluiten</td>
                                <td>
                                    <input
                                        type={'checkbox'}
                                        defaultChecked={settings.getExcludeSoldOut()}
                                        onChange={(e) => {
                                            settings.setExcludeSoldOut(e.target.checked)
                                        }}
                                    />
                                </td>
                            </tr>

                            <tr>
                                <td>Category selectie</td>
                                <td>
                                    <input
                                        type={'checkbox'}
                                        defaultChecked={settings.getShowCategorySelection()}
                                        onChange={(e) => {
                                            settings.setShowCategorySelection(e.target.checked)
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </SelectContainer>

                <SelectContainer>
                    Extra categorie blokken:
                    <br />
                    <MultiSelect
                        onChange={(e) => settings.setCategoryLocations(Array.from(e))}
                        defaultValue={settings.getCategoryLocations()}
                        options={rooms}
                        isMulti
                    />
                </SelectContainer>

                <SelectContainer>
                    Event detail settings:
                    <br />
                    <table>
                        <tbody>
                            <tr>
                                <td>Toon plattegrond</td>
                                <td>
                                    <input
                                        type={'checkbox'}
                                        defaultChecked={settings.getShowLayout() || false}
                                        onChange={(e) => settings.setShowLayout(e.target.checked)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Datum</td>
                                <td>
                                    <input
                                        type={'checkbox'}
                                        defaultChecked={settings.getShowDateOnCard() || false}
                                        onChange={(e) =>
                                            settings.setShowDateOnCard(e.target.checked)
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>vrije plaatsen</td>
                                <td>
                                    <input
                                        type={'checkbox'}
                                        defaultChecked={
                                            settings.getShowAvailableSeatsOnCard() || false
                                        }
                                        onChange={(e) =>
                                            settings.setShowAvailableSeatsOnCard(e.target.checked)
                                        }
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Duratie</td>
                                <td>
                                    <input
                                        type={'checkbox'}
                                        defaultChecked={settings.getShowDurationOnCard() || false}
                                        onChange={(e) =>
                                            settings.setShowDurationOnCard(e.target.checked)
                                        }
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </SelectContainer>

                <SelectContainer>
                    Anoniem boeken:
                    <br />
                    <Select
                        onChange={(e) => settings.setAnonymousBooking(e.target.value as any)}
                        defaultValue={settings.getAnonymousBooking() ?? ''}
                    >
                        <option value="">Anoniem</option>
                        <option value="sameDayAnonymous">De zelfde dag anoniem</option>
                        <option value="account">Account</option>
                    </Select>
                </SelectContainer>

                <SelectContainer>
                    Winkelmandje:
                    <br />
                    <input
                        type={'checkbox'}
                        defaultChecked={settings.getCartEnabled()}
                        onChange={(e) => settings.setCartEnabled(e.target.checked)}
                    />
                </SelectContainer>

                <SelectContainer>
                    Zoekbalk:
                    <br />
                    <input
                        type={'checkbox'}
                        defaultChecked={settings.getSearchEnabled()}
                        onChange={(e) => settings.setSearchEnabled(e.target.checked)}
                    />
                </SelectContainer>
            </Content>
        </>
    )
}
