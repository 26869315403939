const fileExists = (file: string) => {
    try {
        const xhr = new XMLHttpRequest()
        xhr.open('HEAD', file, false)
        xhr.send()

        return xhr.status === 200
    } catch (e) {
        return false
    }
}

export const getFloorplanImageUrl = ({
    baseUrl,
    locationId,
    category = null
}: {
    baseUrl: string
    locationId: number
    category?: 'film' | 'theater' | null
}) => {
    const categoryImage = `${baseUrl}/${locationId}_${category}.jpg`
    const floorplanImage = `${baseUrl}/${locationId}.jpg`
    const categoryFloorplan = fileExists(categoryImage)
    const floorplan = fileExists(floorplanImage)

    if (categoryFloorplan === true) {
        return categoryImage
    } else if (floorplan === true) {
        return floorplanImage
    } else {
        return null
    }
}
