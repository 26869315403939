import { max, min, toPairs, uniqBy } from 'lodash'
import moment from 'moment'
import 'moment/locale/nl'
import { useEffect, useMemo, useRef, useState } from 'react'
import Calendar from 'react-calendar'
import { useService } from '../../Container/ContainerContext'
import { useCart } from '../../Context/CartContext'
import { getEvents } from '../../Hooks'
import { useEventsDates } from '../../Hooks/useEventsDates'
import { getGroupedEvents } from '../../Hooks/useGroupedEvents'
import { useIsInViewport } from '../../Hooks/useIsInViewPort'
import { useRegions } from '../../Hooks/useRegions'
import { IEvent } from '../../Model'
import { ISettings } from '../../Settings/Settings'
import { settingsId } from '../../Settings/container'
import { ApiAgent, IEventsFilter } from '../../api'
import { apiAgentId } from '../../api/container'
import Button from '../Button'
import GroupedEventCard from '../GroupedEventCard'
import { BackButton } from '../Header/styled'
import {
    CalendarWrapper,
    ClearFiltersButton,
    ContentWrapper,
    CurrentFilters,
    Filter,
    FilterItem,
    FilterTitle,
    FilterWrapper,
    Filters,
    RefDiv,
    ToggleFilterButton,
    Wrapper
} from './StyledOverview'

export const Overview = () => {
    const apiAgent = useService<ApiAgent>(apiAgentId)
    const settings = useService<ISettings>(settingsId)

    type IGenre = { genre: string; genre_id: number }

    const [date, setDate] = useState(new Date())
    const [genre, setGenre] = useState<IGenre | undefined>()
    const [region, setRegion] = useState<string | undefined>()

    const [loading, setLoading] = useState({ loading: false })

    const [scrollDateUntil, setScrollDateUntil] = useState<Date>()

    const { category, setCategory, search, setSearch } = useCart()

    useEffect(() => {
        setDisplayedEvents([])
        setDate(new Date())
        setScrollDateUntil(undefined)
    }, [search])

    const { dates: availableDates, status: datesStatus } = useEventsDates({
        filter: {
            type: 'date',
            category: category?.type === 'category' ? category.category : undefined,
            location: category?.type === 'location' ? category.location : undefined
        }
    })

    const [events, setEvents] = useState<IEvent[]>()
    const [groupedEvents, setGroupedEvents] = useState<IEvent[][]>()

    // const { events } = useEvents({
    //     disabled: !availableDates || datesStatus !== 'success',
    //     filter: eventsFilter
    // })

    const pairedDates = useMemo(() => toPairs(availableDates), [availableDates])

    const actualDates = useMemo(() => {
        return pairedDates.map((v) => new Date(v[0]))
    }, [pairedDates])

    const { regions } = useRegions()

    const [showFilters, setShowFilters] = useState(false)

    const availableGenres = uniqBy(
        events?.map((e) => {
            return { genre: e.genre, genre_id: e.genre_id }
        }),
        'genre'
    ).filter(Boolean)

    const availableRegions = regions

    const activeFilters = [
        moment(date).isSame(moment(), 'day') ? false : moment(date).locale('NL').format('LL'),
        region,
        genre?.genre
    ]
        .filter(Boolean)
        .join(' & ')

    const ref = useRef(null)

    const [displayedEvents, setDisplayedEvents] = useState(groupedEvents)

    const inView = useIsInViewport(ref)

    const joinWithoutDupes = (A: IEvent[][], B: IEvent[][]) => {
        const uniqueIds = new Set<string>()

        return [...A, ...B].filter((eventArray) => {
            const id = `${eventArray[0].id}`

            return uniqueIds.has(id) ? false : uniqueIds.add(id)
        })
    }

    const unsetDisplayedEvents = () => {
        setDisplayedEvents([])
    }

    const deselectCategory = () => {
        setCategory(undefined)

        setDate(new Date())
        setScrollDateUntil(undefined)
        setGenre(undefined)
        setRegion(undefined)
        setSearch('')

        unsetDisplayedEvents()
    }

    useEffect(() => {
        fetchEvents()
    }, [date, genre]) // eslint-disable-line

    useEffect(() => {
        if (inView && settings.getEndlessScroll()) {
            fetchEvents()
        }
    }, [inView]) // eslint-disable-line

    useEffect(() => {
        fetchEvents()
    }, [availableDates]) // eslint-disable-line

    const calcDateRange = () => {
        const fromDate = scrollDateUntil
            ? pairedDates.find((d) => new Date(d[0]) > scrollDateUntil)
            : min(pairedDates)

        let untilDate: [string, number] | undefined
        let nextDate: [string, number] | undefined

        // Simple way of finding the 16 next available shows
        if (fromDate) {
            let j = 0

            for (let i = 0; i < 50; i++) {
                // eslint-disable-next-line no-loop-func
                nextDate = pairedDates.find((d) => d > (untilDate || fromDate!))

                if (nextDate && j < 16) {
                    j += nextDate[1]
                    untilDate = nextDate
                } else {
                    break
                }
            }
        }

        const f = fromDate ? new Date(fromDate[0]) : new Date()
        return { fromDate: f, untilDate: untilDate ? new Date(untilDate[0]) : f }
    }

    const fetchEvents = async () => {
        if (loading.loading || !availableDates || datesStatus !== 'success') {
            return
        }

        loading.loading = true
        setLoading({ loading: true })

        const { fromDate, untilDate } = calcDateRange()

        const eventsFilter: IEventsFilter = {
            type: 'date',
            ...(settings.getEndlessScroll()
                ? {
                      dateFrom: fromDate,
                      dateUntil: untilDate
                  }
                : {
                      dateFrom: date,
                      dateUntil: date
                  }),
            genre: genre?.genre_id,
            category: category?.type === 'category' ? category.category : undefined,
            location: category?.type === 'location' ? category.location : undefined,
            search
        }

        const { events } = await getEvents({
            apiAgent,
            filter: eventsFilter
        })

        const filteredEvents = events.filter((g) => {
            const now = moment()

            return (
                (!genre ||
                    (g.genre_id === genre.genre_id &&
                        (!region ||
                            regions
                                .find((r) => r.title === region)
                                ?.data.find((d) => d.id === g.location_id)))) &&
                moment(g.from).isAfter(now.subtract(10, 'minutes'))
            )
        })

        const { groupedEvents } = getGroupedEvents(filteredEvents)

        setEvents(events)
        setGroupedEvents(groupedEvents)

        if (!search && settings.getEndlessScroll()) {
            if ((groupedEvents?.length || 0) > 0) {
                setDisplayedEvents((d) => joinWithoutDupes(d || [], groupedEvents))
            }
        } else {
            setDisplayedEvents(groupedEvents)
        }

        setLoading({ loading: false })

        setScrollDateUntil(untilDate)
    }

    return (
        <Wrapper>
            {settings.areFiltersEnabled() &&
                (!!category || !settings.getShowCategorySelection()) && (
                    <ToggleFilterButton onClick={() => setShowFilters(!showFilters)}>
                        {showFilters
                            ? `Toon aanbod (${groupedEvents?.length})`
                            : settings.getFilterStatus('calendar')
                            ? 'Kies data en filters'
                            : 'Toon filters'}
                    </ToggleFilterButton>
                )}

            {showFilters && (!!category || !settings.getShowCategorySelection()) && (
                <FilterWrapper>
                    {settings.getFilterStatus('calendar') && actualDates.length > 0 && (
                        <CalendarWrapper>
                            <Calendar
                                onChange={setDate}
                                value={date}
                                minDate={min(actualDates)}
                                maxDate={max(actualDates)}
                                tileDisabled={({ date, view }) =>
                                    !actualDates.find((d) =>
                                        moment(d).isSame(
                                            date,
                                            view === 'month'
                                                ? 'day'
                                                : view === 'year'
                                                ? 'month'
                                                : 'year'
                                        )
                                    )
                                }
                            />
                        </CalendarWrapper>
                    )}

                    <Filters>
                        {settings.getFilterStatus('region') && availableRegions.length > 0 && (
                            <Filter>
                                <FilterTitle>Regios</FilterTitle>

                                <ul>
                                    <FilterItem selected={!region}>
                                        <Button
                                            disabled={!region}
                                            onClick={() => setRegion(undefined)}
                                        >
                                            Alle regios
                                        </Button>
                                    </FilterItem>

                                    {availableRegions.map((r) => (
                                        <FilterItem key={r.title} selected={r.title === region}>
                                            <Button
                                                disabled={region === r.title}
                                                onClick={() => setRegion(r.title)}
                                            >
                                                {r.title}
                                            </Button>
                                        </FilterItem>
                                    ))}
                                </ul>
                            </Filter>
                        )}

                        {settings.getFilterStatus('genre') && availableGenres.length > 0 && (
                            <Filter>
                                <FilterTitle>Genres</FilterTitle>

                                <ul>
                                    <FilterItem selected={!genre}>
                                        <Button
                                            disabled={!genre}
                                            onClick={() => {
                                                setGenre(undefined)
                                                if (settings.getEndlessScroll()) {
                                                    unsetDisplayedEvents()
                                                }
                                            }}
                                        >
                                            Alle genres
                                        </Button>
                                    </FilterItem>

                                    {availableGenres.map((g) => (
                                        <FilterItem
                                            key={g.genre}
                                            selected={g.genre_id === genre?.genre_id}
                                        >
                                            <Button
                                                disabled={genre?.genre_id === g.genre_id}
                                                onClick={() => {
                                                    setGenre(g)
                                                    unsetDisplayedEvents()
                                                }}
                                            >
                                                {g.genre}
                                            </Button>
                                        </FilterItem>
                                    ))}
                                </ul>
                            </Filter>
                        )}
                    </Filters>
                </FilterWrapper>
            )}

            {!showFilters && (!!category || !settings.getShowCategorySelection()) && (
                <>
                    <CurrentFilters>
                        {activeFilters && (
                            <>
                                {`Er wordt gefilterd op: ${activeFilters}`}

                                <ClearFiltersButton
                                    onClick={() => {
                                        setDate(new Date())
                                        setScrollDateUntil(undefined)
                                        setGenre(undefined)
                                        setRegion(undefined)

                                        if (settings.getEndlessScroll()) {
                                            unsetDisplayedEvents()
                                        }
                                    }}
                                >
                                    Verwijder
                                </ClearFiltersButton>
                            </>
                        )}
                    </CurrentFilters>

                    <ContentWrapper>
                        {settings.getShowCategorySelection() && (
                            <BackButton onClick={() => deselectCategory()}>Terug</BackButton>
                        )}

                        {displayedEvents?.map((g) => {
                            const first = g.slice(0, 1)[0]
                            return <GroupedEventCard key={'group_' + first.id} groupedEvent={g} />
                        })}
                    </ContentWrapper>
                </>
            )}

            <RefDiv ref={ref}></RefDiv>
        </Wrapper>
    )
}
