import { css } from 'styled-components'
import { styled } from '../../styles/styled'

import Button from '../Button'

export const Wrapper = styled.div`
    background-color: white;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
`

export const ImageContainer = styled.div`
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
`

export const Image = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;

    img {
        width: 100%;
        object-fit: cover;
    }
`

export const EventTitle = styled.div`
    font-size: 2em;
    font-weight: 600;
`
export const EventSubTitle = styled.div`
    font-size: 1.5em;
    font-weight: 400;
`
export const EventLocation = styled.div`
    line-height: 1.4rem;
`

export const EventDuration = styled.div`
    line-height: 1.4rem;
`

export const EventAvailableSeats = styled.div`
    line-height: 1.4rem;
`

export const EventPerformanceDate = styled.div`
    line-height: 1.4rem;
`

export const EventDates = styled.div`
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    margin-top: auto;
    margin-bottom: -10px;
`
export const EventDate = styled(Button)<{ soldOut?: boolean }>`
    margin-bottom: 10px;

    ${({ soldOut = false }) =>
        soldOut
            ? css`
                  background-color: gray;
                  border: 1px solid gray;
              `
            : ''}
`
export const ImageTag = styled.span`
    position: relative;
    top: 3rem;
    left: -0.25rem;
    padding: 0.25rem;
    z-index: 100;
    font-size: 0.75rem;
    color: white;
    background-color: ${({ theme }) => theme.imageTagColor};
`

export const EventInfo = styled.div`
    padding: 10px;
    position: relative;

    ${({ theme }) =>
        theme.locationImg
            ? css`
                  &:after {
                      position: absolute;
                      right: 0.9375rem;
                      top: 0.625rem;
                      width: 2.5rem;
                      height: 2.5rem;
                      content: url(${({ theme }) => theme.locationImg});
                  }

                  ${EventTitle} {
                      padding-right: 60px;
                  }

                  ${EventSubTitle} {
                      padding-right: 60px;
                  }
              `
            : ''}
`
