import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import { useService } from '../../Container/ContainerContext'
import { useAppContext } from '../../Context/AppContext'
import { settingsId } from '../../Settings/container'
import { ISettings } from '../../Settings/Settings'

export const PrivateRoute = ({ children }: { children: ReactNode }) => {
    const settings = useService<ISettings>(settingsId)
    const appContext = useAppContext()
    const navigate = useNavigate()

    if (!appContext.appSettings?.baseUrl || !settings.getCode()) {
        navigate('/setup')
        return null
    }

    return <>{children}</>
}
