import { useEffect, RefObject } from 'react'

type useOnOutsideClickProps = {
    active: boolean
    onOutsideClick: () => void
    nodes: RefObject<HTMLDivElement>[]
}

export const useOnOutsideClick = ({ onOutsideClick, active, nodes }: useOnOutsideClickProps) => {
    useEffect(() => {
        if (active) {
            const handleClickOutside = (e: MouseEvent) => {
                if (
                    !nodes.some(
                        (element) =>
                            element.current && element.current.contains(e.target as HTMLDocument)
                    )
                ) {
                    onOutsideClick()
                }
            }

            document.addEventListener('mousedown', handleClickOutside)

            return () => {
                document.removeEventListener('mousedown', handleClickOutside)
            }
        }

        return
    }, [active, onOutsideClick, nodes])
}
