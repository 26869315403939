import axios from 'axios'

type IApiResponse =
    | {
          success: true
          baseUrl?: string
          floorplanBaseUrl?: string
          apiPwMappingUrl?: string
          logo?: string
          pwUrl?: string
          imageUrl?: string
      }
    | { success: false; errorMessage: string }

export class SetupApiAgent {
    getInfo = async (code: string) => {
        if (code === 'dev') {
            return {
                success: true,
                baseUrl: 'http://itix.algemeen.nl-trunk.stan.o.xotap.nl',
                floorplanBaseUrl: '',
                apiPwMappingUrl: '',
                logo: 'https://www.zeelandtheaters.nl/img/logo.svg',
                pwUrl: 'https://www.zeelandtheaters.nl'
            } as IApiResponse
        }

        const result = await axios.get<IApiResponse>(`https://portal.itix.nl/kiosk/?code=${code}`)

        return result.data
    }
}
