import {
    showOverridableColorScheme,
    showOverridableColorSchemeText,
    useTheme
} from '../../Context/ThemeContext'
import Colorpicker from '../Colorpicker'
import { AddButton, CodeCheck, RemoveButton, ShowTheme, ShowThemes, Wrapper } from './styled'

export const Colorscheme = () => {
    const { theme, updateTheme, showThemes } = useTheme()

    // Hot reloading breaks without this check
    if (!theme) {
        return null
    }

    return (
        <Wrapper>
            <table>
                <tbody>
                    {(Object.keys(theme) as (keyof typeof theme)[]).map((key) => (
                        <tr key={key}>
                            <td>{key}</td>
                            <Colorpicker
                                hexColor={theme[key]}
                                onChange={(hex) => updateTheme(key, hex)}
                            />
                        </tr>
                    ))}
                </tbody>
            </table>

            <ShowThemes>
                {showThemes.getShowThemes().map((t) => (
                    <ShowTheme key={t.id}>
                        <input
                            className="showTheme"
                            value={t.code}
                            onChange={(e) => {
                                showThemes.updateShowThemeCode({ id: t.id, code: e.target.value })
                            }}
                        />

                        <CodeCheck style={{ backgroundColor: t.valid ? 'green' : 'red' }}>
                            <span>{t.valid ? 'Ok' : 'Error'}</span>
                        </CodeCheck>

                        <RemoveButton onClick={() => showThemes.removeShowTheme({ id: t.id })}>
                            Remove
                        </RemoveButton>

                        <table>
                            <tbody>
                                {(Object.keys(theme) as (keyof typeof theme)[])
                                    .filter((key) => showOverridableColorScheme.includes(key))
                                    .map((key) => (
                                        <tr key={key}>
                                            <td>{key}</td>

                                            <Colorpicker
                                                hexColor={t.theme[key] || theme[key]}
                                                onChange={(hex) =>
                                                    showThemes.updateShowTheme({
                                                        id: t.id,
                                                        key,
                                                        value: hex
                                                    })
                                                }
                                            />
                                        </tr>
                                    ))}
                            </tbody>
                        </table>

                        <table>
                            <tbody>
                                {(Object.keys(theme) as (keyof typeof theme)[])
                                    .filter((key) => showOverridableColorSchemeText.includes(key))
                                    .map((key) => (
                                        <tr key={key}>
                                            <td>{key}</td>

                                            <input
                                                value={t.theme[key] || theme[key]}
                                                onChange={(e) =>
                                                    showThemes.updateShowTheme({
                                                        id: t.id,
                                                        key,
                                                        value: e.target.value
                                                    })
                                                }
                                            />
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </ShowTheme>
                ))}

                <AddButton onClick={() => showThemes.addShowTheme()}>Nieuwe showfilter</AddButton>
            </ShowThemes>
        </Wrapper>
    )
}
