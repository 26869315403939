import { useTheme } from '../../Context/ThemeContext'
import { Wrapper } from './styled'

export const Spinner = () => {
    const {
        theme: { spinnerColor }
    } = useTheme()

    return (
        <Wrapper>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="137px"
                height="137px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
            >
                <g transform="rotate(0 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.6359649122807017s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(12 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.6140350877192983s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(24 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.5921052631578947s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(36 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.5701754385964912s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(48 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.5482456140350876s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(60 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.5263157894736842s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(72 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.5043859649122807s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(84 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.4824561403508772s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(96 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.4605263157894737s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(108 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.43859649122807015s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(120 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.41666666666666663s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(132 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.39473684210526316s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(144 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.37280701754385964s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(156 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.3508771929824561s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(168 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.3289473684210526s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(180 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.30701754385964913s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(192 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.2850877192982456s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(204 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.2631578947368421s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(216 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.2412280701754386s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(228 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.21929824561403508s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(240 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.19736842105263158s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(252 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.17543859649122806s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(264 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.15350877192982457s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(276 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.13157894736842105s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(288 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.10964912280701754s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(300 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.08771929824561403s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(312 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.06578947368421052s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(324 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.043859649122807015s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(336 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="-0.021929824561403508s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
                <g transform="rotate(348 50 50)">
                    <rect x="48.5" y="29.5" rx="0" ry="0" width="3" height="3" fill={spinnerColor}>
                        <animate
                            attributeName="opacity"
                            values="1;0"
                            keyTimes="0;1"
                            dur="0.6578947368421053s"
                            begin="0s"
                            repeatCount="indefinite"
                        ></animate>
                    </rect>
                </g>
            </svg>
        </Wrapper>
    )
}
