import axios from 'axios'
import moment from 'moment'
import { serialize } from 'object-to-formdata'
import { IConfig } from '../Config/Config'
import { settingsApiPwMappingUrl, settingsBaseUrl } from '../Context/AppContext'
import {
    ApiResponse,
    IEvent,
    IGetPriceCodesRequest,
    IGetRanksRequest,
    IPriceCode,
    IRank
} from '../Model'
import { ISettings } from '../Settings/Settings'

type IRegion = {
    title: string
    data: { id: number; title: string }[]
}

export type IApiEvent = {
    eventId: number
    rank: number
    priceCodes: { [x: number]: number }
    location: string | undefined
    from: string
}

export type IEventsFilter =
    | { type: 'id'; eventId: number }
    | {
          type: 'date'
          dateFrom: Date
          dateUntil: Date
          genre?: number
          category?: string
          location?: string
          search?: string
      }

export class ApiAgent {
    protected token: string

    protected config: IConfig
    protected settings: ISettings

    constructor({ config, settings }: { config: IConfig; settings: ISettings }) {
        this.token = config.token

        this.config = config
        this.settings = settings
    }

    startPayment = async (paymentInfo: { events: IApiEvent[]; email: string | undefined }) => {
        const result = await axios.post(
            `${settingsBaseUrl}/kiosk/reservation_save`,
            {
                reservation: {
                    printer_id: this.settings.getPrinter(),
                    pin_terminal: this.settings.getTerminal(),
                    events: paymentInfo.events.map((event) => ({
                        voorstelling_id: event.eventId,
                        rang_id: event.rank,
                        pricecodes: event.priceCodes,
                        locatie: event.location
                    })),
                    email: paymentInfo.email
                }
            },
            {
                headers: {
                    'login-token': this.token
                }
            }
        )

        return result.data
    }

    getPrinters = async () => {
        const result = await axios.get<{
            success: string
            printers: {
                id: string
                name: string
            }[]
        }>(`${settingsBaseUrl}/kiosk/get_printers`, {
            headers: {
                'login-token': this.token
            }
        })

        return result.data
    }

    getTerminals = async () => {
        const result = await axios.get<{
            success: string
            terminals: {
                id: string
                name: string
            }[]
        }>(`${settingsBaseUrl}/kiosk/get_terminals`, {
            headers: {
                'login-token': this.token
            }
        })

        return result.data
    }

    getLocations = async () => {
        const result = await axios.get<{
            success: string
            locations: {
                id: string
                zaal_id: number
                svg: string
            }[]
        }>(`${settingsBaseUrl}/kiosk/get_locations`, {
            headers: {
                'login-token': this.token
            }
        })

        return result.data
    }

    getRooms = async () => {
        const result = await axios.get<{
            success: string
            rooms: {
                id: string
                name: string
            }[]
        }>(`${settingsBaseUrl}/kiosk/get_zalen`, {
            headers: {
                'login-token': this.token
            }
        })

        return result.data
    }

    getEvents = async ({ filter }: { filter: IEventsFilter }): Promise<ApiResponse<IEvent[]>> => {
        const rooms = this.settings.getRooms().map((room) => {
            return room.value
        })

        const locationFilter: any = {}

        if (rooms.length) {
            locationFilter.in = rooms
        }

        if (filter.type === 'date') {
            if (filter.location) {
                locationFilter.eq = filter.location
            } else {
                locationFilter.nin = this.settings
                    .getCategoryLocations()
                    .map((r) => r.value)
                    .filter((r) => r !== filter.location)
            }
        }

        const result = await axios.post(
            `${settingsBaseUrl}/framework/public/ajax/site/show.php?action=getShows`,
            {
                filter: {
                    reservationMethodId: 12,
                    show: {
                        visible_from: {
                            lt: `${moment().format('YYYY-MM-DD HH:mm:ss')}`
                        },

                        presale_start: {
                            lt: `${moment().format('YYYY-MM-DD HH:mm:ss')}`
                        },

                        location_id: locationFilter,

                        ...(filter.type === 'id' && {
                            id: {
                                eq: filter.eventId
                            }
                        }),

                        ...(filter.type === 'date' && {
                            ...(!filter.search && {
                                from: {
                                    gt: `${moment(filter.dateFrom).format('YYYY-MM-DD')} 00:00:00`,
                                    lt: `${moment(filter.dateUntil).format('YYYY-MM-DD')} 23:59:59`
                                }
                            }),
                            sale_status_backend: {
                                in: [
                                    'reserveren',
                                    ...(this.settings.getExcludeSoldOut() ? [] : ['uitverkocht'])
                                ]
                            },
                            ...(filter.search && { original_title: { like: filter.search } })
                        })
                    },
                    showindex: {
                        ...(filter.type === 'date' && {
                            ...(filter.genre && {
                                genre_id: {
                                    eq: filter.genre
                                }
                            }),
                            ...(filter.category && {
                                category: {
                                    eq: filter.category
                                }
                            })
                        })
                    }
                },
                ...(filter.type === 'date' && filter.search && { limit: 20 })
            }
        )

        if (result.status === 200 && result.data.status === 'OK') {
            return {
                success: true,
                data: result.data.message.map((event: any) => ({
                    id: event.id,
                    category: event.category,
                    from: event.from,
                    until: event.until,
                    title: event.title,
                    subtitle: event.subtitle,
                    salestatus: event.salestatus,
                    location_name: event.location_name,
                    location_id: event.location_id,
                    genre: event.genre,
                    genre_id: event.genre_id,
                    availableSeats: event.seats_available,
                    prices: event.prices
                }))
            }
        }
        return { success: false }
    }

    getEventsDates = async ({
        filter
    }: {
        filter: Partial<IEventsFilter>
    }): Promise<ApiResponse<{ [date: string]: number }>> => {
        const rooms = this.settings.getRooms().map((room) => {
            return room.value
        })

        const locationFilter: any = {}

        if (rooms.length) {
            locationFilter.in = rooms
        }

        if (filter.type === 'date') {
            if (filter.location) {
                locationFilter.eq = filter.location
            } else {
                locationFilter.nin = this.settings
                    .getCategoryLocations()
                    .map((r) => r.value)
                    .filter((r) => r !== filter.location)
            }
        }

        const result = await axios.post(
            `${settingsBaseUrl}/framework/public/ajax/site/show.php?action=getShowsDates`,
            {
                filter: {
                    addAmount: true,
                    reservationMethodId: 12,
                    show: {
                        visible_from: {
                            lt: `${moment().format('YYYY-MM-DD HH:mm:ss')}`
                        },

                        presale_start: {
                            lt: `${moment().format('YYYY-MM-DD HH:mm:ss')}`
                        },

                        location_id: locationFilter,

                        from: {
                            gt: `${moment().format('YYYY-MM-DD')} 00:00:00`
                        },

                        ...(filter.type === 'date' && {
                            sale_status_backend: {
                                in: [
                                    'reserveren',
                                    ...(this.settings.getExcludeSoldOut() ? [] : ['uitverkocht'])
                                ]
                            }
                        })
                    },
                    showindex: {
                        ...(filter.type === 'date' && {
                            ...(filter.category && {
                                category: {
                                    eq: filter.category
                                }
                            })
                        })
                    }
                }
            }
        )

        if (result.status === 200 && result.data.status === 'OK') {
            return {
                success: true,
                data: result.data.message
            }
        }
        return { success: false }
    }

    getRanks = async ({ eventId }: IGetRanksRequest): Promise<ApiResponse<IRank[]>> => {
        const result = await axios.post(
            `${settingsBaseUrl}/framework/public/ajax/site/show.php?action=getRanks&showId=${eventId}`
        )

        if (result.status === 200 && result.data.status === 'OK') {
            return {
                success: true,
                data: result.data.message.map((rank: any) => ({
                    id: rank.id,
                    name: rank.title,
                    price: rank.price,
                    order: rank.volgorde,
                    availableSeats: rank.seats_available,
                    locations: rank.locations
                }))
            }
        }

        return {
            success: false
        }
    }

    getPricecode = async ({
        eventId,
        rankId,
        amount
    }: IGetPriceCodesRequest): Promise<ApiResponse<IPriceCode[][]>> => {
        const result = await axios.post(
            `${settingsBaseUrl}/framework/public/ajax/site/show.php?action=getPriceCodes`,
            serialize({
                showId: eventId,
                rankId,
                amount,
                reservationMethodId: 12
            }),
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        )

        if (result.status === 200 && result.data.status === 'OK') {
            return {
                success: true,
                data: result.data.message.map((p: any) =>
                    p.map((pricecode: any) => ({
                        id: parseInt(pricecode.id),
                        name: pricecode.label,
                        price: parseFloat(pricecode.prijs),
                        surcharge: pricecode.toeslag
                    }))
                )
            }
        }

        return {
            success: false
        }
    }

    getRegions = async (): Promise<ApiResponse<IRegion[]>> => {
        const apiPwMappingUrl = settingsApiPwMappingUrl

        if (!apiPwMappingUrl) {
            return { success: false }
        }

        const result = await axios.post<{
            regions: IRegion[]
        }>(apiPwMappingUrl)

        if (result.status === 200 && result.data.regions) {
            return {
                success: true,
                data: result.data.regions
            }
        }

        return { success: false }
    }
}
