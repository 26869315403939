import { createContext, ReactNode, useContext, useState } from 'react'
import { useService } from '../Container/ContainerContext'
import { IPriceCode } from '../Model'
import { IEvent } from '../Model/Event'
import { settingsId } from '../Settings/container'
import { ISettings } from '../Settings/Settings'

export type ICartContext = ReturnType<typeof useInitialState>

export type ICart = { events: ICartEvent[] }

type ICartEvent = {
    event: IEvent
    rank: number
    selectedPriceCodes: { [x: number]: number }
    location: string | undefined
    priceCodes: IPriceCode[]
}

const useInitialState = () => {
    const settings = useService<ISettings>(settingsId)

    const [cart, _setCart] = useState<ICart>({ events: [] })
    const [category, setCategory] = useState<
        { type: 'category'; category: string } | { type: 'location'; location: string } | undefined
    >()
    const [search, setSearch] = useState<string>('')

    const setCart = (cb: (oldCart: ICart) => ICart) => {
        _setCart((c) => {
            const result = cb(c)
            settings.setCart(c)
            return { ...result }
        })
    }

    return {
        cart,
        addToCart: (event: ICartEvent) => {
            setCart((c) => {
                // Overwrites existing
                c.events = c.events.filter((e) => e.event.id !== event.event.id)
                c.events.push(event)

                return c
            })
        },
        removeFromCart: (eventId: number) =>
            setCart((c) => {
                c.events = c.events.filter((e) => e.event.id !== eventId)

                return c
            }),
        category,
        setCategory,
        search,
        setSearch
    }
}

const Context = createContext<ICartContext>({} as ICartContext)

export const CartContext = ({ children }: { children: ReactNode }) => {
    const initialState = useInitialState()

    return <Context.Provider value={initialState}>{children}</Context.Provider>
}

export const useCart = () => useContext(Context)
