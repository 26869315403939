import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useService } from '../../Container/ContainerContext'
import { ThemeContext } from '../../Context/ThemeContext'
import { useImage } from '../../Hooks'
import { EventCategory, IEvent } from '../../Model/Event'
import { settingsId } from '../../Settings/container'
import { ISettings } from '../../Settings/Settings'
import {
    EventAvailableSeats,
    EventDate,
    EventDates,
    EventDuration,
    EventInfo,
    EventLocation,
    EventPerformanceDate,
    EventSubTitle,
    EventTitle,
    Image,
    ImageContainer,
    ImageTag,
    Wrapper
} from './styled'

export const GroupedEventCard = ({ groupedEvent }: { groupedEvent: IEvent[] }) => {
    const navigate = useNavigate()

    const first = groupedEvent.slice(0, 1)[0]
    const { image, defaultImgStyle } = useImage(first.id)

    const settings = useService<ISettings>(settingsId)

    return (
        <ThemeContext event={first}>
            <Wrapper>
                <ImageContainer
                    onClick={() => {
                        if (first.salestatus !== 'uitverkocht') {
                            navigate('/bestel/' + first.id)
                        }
                    }}
                >
                    <Image>{image && <img alt="" src={image} style={defaultImgStyle} />}</Image>
                    <ImageTag>
                        {first.category === EventCategory.THEATER ? 'Theater' : 'Cinema'}
                    </ImageTag>
                </ImageContainer>
                <EventInfo
                    onClick={() => {
                        if (first.salestatus !== 'uitverkocht') {
                            navigate('/bestel/' + first.id)
                        }
                    }}
                >
                    <EventTitle>{first.title}</EventTitle>
                    <EventSubTitle>{first.subtitle}</EventSubTitle>
                    <EventLocation>{first.location_name}</EventLocation>

                    {first.until && settings.getShowDurationOnCard() && (
                        <EventDuration>
                            Speelduur: {moment(first.until).diff(first.from, 'minutes')} minuten
                        </EventDuration>
                    )}

                    {settings.getShowAvailableSeatsOnCard() && (
                        <EventAvailableSeats>
                            {first.availableSeats} vrije plaatsen
                        </EventAvailableSeats>
                    )}

                    {settings.getShowDateOnCard() && (
                        <EventPerformanceDate>
                            {moment(first.from).calendar(null, {
                                sameDay: '[Vandaag]',
                                nextDay: '[Morgen]',
                                nextWeek: 'dddd',
                                lastDay: '[Gisteren]',
                                lastWeek: '[Afgelopen] dddd',
                                sameElse: 'DD MMM YYYY'
                            })}
                        </EventPerformanceDate>
                    )}
                </EventInfo>
                <EventDates>
                    {groupedEvent.map((s) => (
                        <EventDate
                            soldOut={s.salestatus === 'uitverkocht'}
                            onClick={() => {
                                s.salestatus !== 'uitverkocht' && navigate('/bestel/' + s.id)
                            }}
                            key={'bestel_' + s.id}
                        >
                            {moment(s.from).format('H:mm')}
                        </EventDate>
                    ))}
                </EventDates>
            </Wrapper>
        </ThemeContext>
    )
}
