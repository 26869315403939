import Bottle, { IContainer } from 'bottlejs'

export const bindToFactory = (
    id: string,
    cb: (container: IContainer) => any
): [string, (container: IContainer) => any] => {
    return [id, cb]
}

export const initContainer = (
    container: Bottle,
    modules: [string, (container: IContainer) => any][]
) => {
    modules.forEach(([id, factory]) => {
        container.factory(id, factory)
    })
}
