import moment from 'moment'
import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { useService } from '../Container/ContainerContext'
import { ApiAgent, ApiCall } from '../api'
import { IEventsFilter } from '../api/ApiAgent'
import { apiAgentId } from '../api/container'

export const useEvents = ({ disabled, filter }: { disabled?: boolean; filter: IEventsFilter }) => {
    const apiAgent = useService<ApiAgent>(apiAgentId)

    const memoKey =
        filter.type === 'date'
            ? {
                  ...filter,
                  dateFrom: moment(filter.dateFrom).format('YYYY-MM-DD'),
                  dateUntil: moment(filter.dateUntil).format('YYYY-MM-DD')
              }
            : filter

    const { data: eventResponse, status } = useQuery(
        [ApiCall.EVENTS, JSON.stringify(memoKey)],
        (_) => apiAgent.getEvents({ filter }),
        {
            refetchInterval: 30000,
            enabled: !disabled
        }
    )

    const events = useMemo(() => {
        return status === 'success' && eventResponse?.success === true
            ? eventResponse.data
                  .filter((s) => {
                      return s.prices.length > 0
                  })
                  .sort((a, b) => {
                      if (moment(a.from).isSame(moment(b.from))) return 0
                      return moment(a.from).isBefore(moment(b.from)) ? -1 : 1
                  })
            : []
    }, [eventResponse, status])

    return { status, events }
}

export const getEvents = async ({
    apiAgent,
    filter
}: {
    apiAgent: ApiAgent
    filter: IEventsFilter
}) => {
    const eventResponse = await apiAgent.getEvents({ filter })

    const events =
        eventResponse?.success === true
            ? eventResponse.data
                  .filter((s) => {
                      return s.prices.length > 0
                  })
                  .sort((a, b) => {
                      if (moment(a.from).isSame(moment(b.from))) return 0
                      return moment(a.from).isBefore(moment(b.from)) ? -1 : 1
                  })
            : []

    return { events }
}
