import { useNavigate, useParams } from 'react-router-dom'

import Content from '../../Components/Content'
import EventDetail from '../../Components/EventDetail'
import Header from '../../Components/Header'

export const Bestel = () => {
    const { eventId } = useParams<{ eventId: string }>()
    const navigate = useNavigate()

    if (!eventId) {
        navigate('/')
        return null
    }

    return (
        <>
            <Header
                onBackButton={() => {
                    navigate('/')
                }}
            />
            <Content>
                <EventDetail eventId={parseInt(eventId)} />
            </Content>
        </>
    )
}
